import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js"
import { isTurboNative } from "../lib/environment"

export default class extends Controller {
  static targets = ["checkout"]

  static get shouldLoad() {
    return !isTurboNative
  }

  async checkoutTargetConnected(target) {
    const key = target.dataset["key"]
    const clientSecret = target.dataset["secret"]
    target.removeAttribute("data-key")
    target.removeAttribute("data-secret")
    this.stripe = await loadStripe(key)
    this.checkout = await this.stripe.initEmbeddedCheckout({ clientSecret })
    this.checkout.mount(target)
  }

  checkoutTargetDisconnected() {
    this.checkout.destroy()
  }
}
