import { Controller } from "@hotwired/stimulus"
import { isTurboNative } from "../lib/environment"

export default class extends Controller {
  signOut(event) {
    if (typeof Intercom !== 'undefined') {
      Intercom('shutdown')
    }
    if (isTurboNative) {
      event.preventDefault()
      event.stopImmediatePropagation()
      window.TurboNativeBridge.postMessage("signOut")
    }
  }
}
