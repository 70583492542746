import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  navigate(event) {
    const hash = `#${event.params.hash}`
    const element = document.querySelector(hash)
    if (element != null) {
      event.preventDefault()
      window.location = hash
    }
  }
}
