import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.index = 0
    this.update()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  update() {
    this.itemTargets.forEach((t, i) => {
      if (i === this.index) {
        t.classList.toggle("hidden", false)
      }
      t.classList.toggle("opacity-0", i !== this.index)
    })
    this.timeout = setTimeout(() => {
      this.index = (this.index + 1) % this.itemTargets.length
      this.update()
    }, this.index === this.itemTargets.length - 1 ? 10000 : 5000)
  }

  fadeOut(event) {
    if (event.target.classList.contains("opacity-0")) {
      event.target.classList.toggle("hidden", true)
    }
  }
}
