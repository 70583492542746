import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autoSubmit: { type: Boolean, default: false } }

  connect() {
    if (this.autoSubmitValue) {
      this.submit()
    }
  }

  submit() {
    setTimeout(() => this.element.submit(), 0)
  }
}
