import { Slideover } from "tailwindcss-stimulus-components"
import { toggleWithState } from "tailwindcss-stimulus-components/src/transition"
import { isTurboNative } from "../lib/environment"

export default class extends Slideover {
  static targets = ["body", "themeColor", "header", "flashes", "hero"]

  headerTargetConnected() {
    const headerHeight = this.headerTarget.offsetHeight
    const sectionObserver = new IntersectionObserver(
        this._sectionChanged.bind(this),
        {
          rootMargin: `-${headerHeight}px 0px 0px 0px`,
          threshold: [0, 1]
        }
    )
    this.element.querySelectorAll("section").forEach(section => sectionObserver.observe(section))
    this.sections = new Map()
  }

  toggleSlideover() {
    super.toggle()
  }

  scrollTo({params: {id}}) {
    setTimeout(() => {
      const element = document.getElementById(id)
      const headerHeight = this.headerTarget.offsetHeight
      const offsetPosition = element.getBoundingClientRect().top + window.scrollY - headerHeight + 2
      window.scrollTo({top: offsetPosition, behavior: "smooth"})
      history.pushState(null, null, `#${id}`)
    }, 0)
  }

  setTheme({params: {value}}) {
    if (!isTurboNative) {
      localStorage.colorScheme = value
      const darkSystemPref = window.matchMedia("(prefers-color-scheme: dark)").matches
      const dark = localStorage.colorScheme === "dark" ||
          (localStorage.colorScheme !== "light" && darkSystemPref)
      this.element.classList.toggle("dark", dark)
      this._updateThemeColors()
    }
  }

  openValueChanged() {
    if (this.hasOverlayTarget) toggleWithState(this.overlayTarget, this.openValue)
    if (this.hasMenuTarget) toggleWithState(this.menuTarget, this.openValue)
    if (this.hasCloseTarget) toggleWithState(this.closeTarget, this.openValue)
    const classes = ["relative", "overflow-hidden", "h-full"]
    classes.forEach(c => this.bodyTarget.classList.toggle(c, this.openValue))
  }

  _sectionChanged(entries, _) {
    entries.forEach(entry => this.sections.set(entry.target, this._sectionIsVisible(entry)))
    this._updateThemeColors()
  }

  _sectionIsVisible(entry) {
    return entry.intersectionRatio > 0
  }

  _updateThemeColors() {
    const visibleSections = [...this.sections].filter(([_, v]) => v).map(([k, _]) => k)
    const referenceElement = visibleSections.length > 0 ? visibleSections[0] : this.bodyTarget
    const style = window.getComputedStyle(referenceElement)
    const color = this._getColor(style, "color")
    const bgColor = this._getColor(style, "background-color")
    const rootStyle = document.documentElement.style
    rootStyle.setProperty("--theme-fg-color", color)
    rootStyle.setProperty("--theme-fg-color-dark", color)
    rootStyle.setProperty("--theme-bg-color", bgColor)
    rootStyle.setProperty("--theme-bg-color-dark", bgColor)
    this.themeColorTarget.setAttribute("content", bgColor)
  }

  _getColor(style, propertyName) {
    let color = style.getPropertyValue(propertyName)
    if (color.replace(/\s+/g, '').toLowerCase() === "rgba(0,0,0,0)") color = null
    return color
  }
}
