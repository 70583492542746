import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link", "tab"]

  connect() {
    if (window.location.hash === "") {
      this.setTarget(this.linkTarget)
    } else {
      this
        .linkTargets
        .filter(t => window.location.hash === t.attributes.href.value)
        .forEach(t => this.setTarget(t))
    }
  }

  setTab(event) {
    this.setTarget(event.target)
  }

  setTarget(target) {
    const tab = target.attributes.href.value
    window.location.hash = tab
    this.linkTargets.forEach(t => t.classList.toggle("active", t === target))
    this.tabTargets.forEach(t => t.classList.toggle("active", t.dataset.tab === tab))
  }
}
