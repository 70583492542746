import { application } from "./application"

import AuthenticationsController from "./authentications_controller.js"
application.register("auth", AuthenticationsController)

import AutoSubmitController from "./auto_submit_controller.js"
application.register("auto-submit", AutoSubmitController)

import CountdownController from "./countdown_controller.js"
application.register("countdown", CountdownController)

import FadeController from "./fade_controller.js"
application.register("fade", FadeController)

import HorizontalScrollController from "./horizontal_scroll_controller.js"
application.register("h-scroll", HorizontalScrollController)

import HtmlController from "./html_controller.js"
application.register("html", HtmlController)

import IntercomController from "./intercom_controller.js"
application.register("intercom", IntercomController)

import NavigationController from "./navigation_controller.js"
application.register("navigation", NavigationController)

import OrderController from "./order_controller.js"
application.register("order", OrderController)

import PasswordVisibilityController from "./password_visibility_controller.js"
application.register("password-visibility", PasswordVisibilityController)

import ProgressController from "./progress_controller.js"
application.register("progress", ProgressController)

import ResponsivePaginationController from "./responsive_pagination_controller.js"
application.register("responsive-pagination", ResponsivePaginationController)

import RevealController from "./reveal_controller.js"
application.register("reveal", RevealController)

import RevealMoreController from "./reveal_more_controller.js"
application.register("reveal-more", RevealMoreController)

import ScrollArrowController from "./scroll_arrow_controller.js"
application.register("scroll-arrow", ScrollArrowController)

import StripeController from "./stripe_controller.js"
application.register("stripe", StripeController)

import TimerController from "../controllers/timer_controller.js"
application.register("timer", TimerController)

import TooltipController from "./tooltip_controller.js"
application.register("tooltip", TooltipController)

import VidstackController from "./vidstack_controller.js"
application.register("vidstack", VidstackController)

import WelcomeContentsController from "./welcome_contents_controller.js"
application.register("welcome-contents", WelcomeContentsController)
