import {Controller} from "@hotwired/stimulus"
import {useResize} from "stimulus-use";

export default class extends Controller {
  static targets = ["item", "loadMoreButton"]

  connect() {
    useResize(this)
    this.rows = 3
  }

  loadMore(event) {
    this.rows += this.rows <= 5 ? 3 : this.rows <= 10 ? 4 : 5
    if ((this.itemTargets.length >= this.rows * this.columns) || this.allLoaded) {
      event.preventDefault()
      this._refresh()
    } else {
      this.requestedPosition = this.nextPosition
    }
  }

  itemTargetConnected(target) {
    this.maxIndex = this.maxIndex != null ? this.maxIndex + 1 : 0
    target.dataset["index"] = this.maxIndex
    this.nextPosition = parseInt(target.dataset["position"]) + 1
    this._updateItem(target)
  }

  loadMoreButtonTargetConnected() {
    this.allLoaded = this.nextPosition === this.requestedPosition
    this._refresh()
  }

  resize() {
    this.columns = this._columnsCount()
    window.requestAnimationFrame(() => this._refresh())
  }

  _refresh() {
    this.itemTargets.forEach(i => this._updateItem(i))
    this.allVisible = this.itemTargets.length <= (this.rows * this.columns)
    if (this.hasLoadMoreButtonTarget) {
      this.loadMoreButtonTarget.href = this.loadMoreButtonTarget.href.replace("position=0", `position=${this.nextPosition}`)
      this.loadMoreButtonTarget.classList.toggle("hidden", this.allLoaded && this.allVisible)
    }
  }

  _updateItem(item) {
    const index = item.dataset["index"]
    item.classList.toggle("hidden", index >= this.rows * this.columns)
  }

  _columnsCount() {
    const gridStyle = window.getComputedStyle(this.element)
    return gridStyle.getPropertyValue("grid-template-columns").split(" ").length
  }
}
