import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "item"]

  connect() {
    const gridComputedStyle = window.getComputedStyle(this.containerTarget)
    const cols = gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length
    this.itemTargets[this.itemTargets.length - 1].classList.toggle("translate-x-[calc(50%+10px)]", cols === 2)
    this.itemTargets[this.itemTargets.length - 1].classList.toggle("col-start-2", cols === 3)
  }
}
