import { Controller } from "@hotwired/stimulus"
import { isTurboNative } from "../lib/environment"

export default class extends Controller {
  show() {
    if (isTurboNative) {
      window.TurboNativeBridge.postMessage("showIntercom")
    } else {
      Intercom("show")
    }
  }
}
