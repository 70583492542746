import {Controller} from "@hotwired/stimulus"
import HLS from "hls.js"
import "vidstack/player"
import "vidstack/player/layouts"
import "vidstack/player/ui"
import "vidstack/icons"

export default class extends Controller {
  static targets = ["saveProgressForm"]

  disconnect() {
    this.element.destroy()
    this.element.remove()
  }

  importHLS(event) {
    const provider = event.detail
    if (provider?.type === "hls") {
      provider.library = HLS
    }
  }

  play() {
    document.querySelectorAll("media-player").forEach(p => {
      if (p !== this.element) {
        p.pause()
      }
    })
    if (this._isSmall()) {
      this.element.enterFullscreen()
    }
    this.startedAt = new Date().toISOString()
  }

  pause() {
    this._sendStats()
  }

  ended() {
    this.element.exitFullscreen()
  }

  fullscreenChanged(event) {
    const isFullscreen = event.detail
    if (this.element.paused && isFullscreen) {
      this.element.play()
    }
    if (!this.element.paused && !isFullscreen && this._isSmall()) {
      this.element.pause()
    }
  }

  enableCaptions(event) {
    const track = this.element.textTracks.getByKind("subtitles")
        .find(track => track.language === document.documentElement.lang)
    if (track) {
      track.mode = "showing"
    }
  }

  _isSmall() {
    return this.element.getBoundingClientRect().width < 700
  }

  _sendStats() {
    if (this.startedAt) {
      const form = this.saveProgressFormTarget
      form._user_session_started.value = this.startedAt
      form._user_session_ended.value = new Date().toISOString()
      form._user_session_completed.value = this.element.currentTime > 0.9 * this.element.duration
      form._user_session_idempotency_key.value = this._guid()
      this.saveProgressFormTarget.requestSubmit()
      this.startedAt = undefined
    }
  }

  // generate a UUID-looking random string, https://stackoverflow.com/a/105074
  _guid() {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }
}
