import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button"]

  toggle() {
    const isPassword = this.inputTarget.type === "password"
    this.buttonTarget.getElementById("strikethrough").classList.toggle("hidden", !isPassword)
    this.inputTarget.type = isPassword ? "text" : "password"
  }
}
