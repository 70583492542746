import { computePosition, flip, shift, offset } from "@floating-ui/dom"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltip"]
  static values = {
    offset: { type: Number, default: 10 },
    timeout: { type: Number, default: 2500 },
  }

  async tooltipUpdate() {
    computePosition(this.element, this.tooltipTarget, {
      middleware: [
        offset(this.offsetValue),
        flip(),
        shift({padding: 15}),
      ],
    }).then(({x, y, placement, middlewareData}) => {
      Object.assign(this.tooltipTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      })
    })
  }

  show() {
    clearTimeout(this.timeout)
    this.tooltipTarget.classList.toggle("hidden", false)
    this.tooltipUpdate()
    if (this.timeoutValue > 0) {
      this.timeout = setTimeout(() => this.tooltipTarget.classList.toggle("hidden", true), this.timeoutValue)
    }
  }

  hide() {
    clearTimeout(this.timeout)
    this.tooltipTarget.classList.toggle("hidden", true)
    this.tooltipUpdate()
  }
}
