import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = { hiddenText: String, visibleText: String }

  connect() {
    this.hidden = true
  }

  toggle(event) {
    this.hidden = !this.hidden
    this.containerTarget.classList.toggle("hidden", this.hidden)
    event.target.innerText = this.hidden ? this.hiddenTextValue : this.visibleTextValue
  }
}
