import {Controller} from "@hotwired/stimulus"
import {useResize} from "stimulus-use"

export default class extends Controller {
  static targets = ["content", "button"]
  static values = { moreText: String, lessText: String }

  connect() {
    this.buttonTarget.innerHTML = this.moreTextValue
    useResize(this)
    this.open = false
  }

  toggle() {
    this.open = !this.open
    this.buttonTarget.innerHTML = this.open ? this.lessTextValue : this.moreTextValue
    this.contentTarget.classList.toggle("line-clamp-6", !this.open)
  }

  resize() {
    if (!this.open) {
      window.requestAnimationFrame(() => {
        this.buttonTarget.classList.toggle("hidden", this.contentTarget.scrollHeight <= this.contentTarget.clientHeight)
      })
    }
  }
}
