import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { timestamp: Number }

  connect() {
    this._tick()
    this.timer = setInterval(this._tick.bind(this), 1000)
    this.element.classList.toggle("hidden", false)
  }

  _tick() {
    const now = new Date().getTime() / 1000
    const secondsLeft = parseInt(this.timestampValue - now)
    if (secondsLeft > 0) {
      const days = Math.floor(secondsLeft / (60 * 60 * 24))
      const hours = Math.floor((secondsLeft % (60 * 60 * 24)) / (60 * 60))
      const minutes = Math.floor((secondsLeft % (60 * 60)) / (60))
      const seconds = Math.floor((secondsLeft % (60)))
      this.element.textContent = `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    } else {
      clearInterval(this.timer)
    }
  }
}
