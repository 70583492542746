import { Controller } from '@hotwired/stimulus'
import {useThrottle} from 'stimulus-use'

export default class extends Controller {
  static throttles = ["calculateActiveIndex"]
  static values = {
    mask: { type: Boolean, default: true },
    scrollBehavior: { type: String, default: "smooth" }
  }
  static targets = ["container", "prev", "next", "item", "dot"]

  connect() {
    useThrottle(this, { wait: 150 })
    this.index = 0
    this._updateClasses()
  }

  scrollToIndexByParam({ params: { index }}) {
    if (index !== this.index) {
      this.index = index
      this._scroll()
      this._updateAndDispatch()
    }
  }

  scrollToIndex({ detail: { index }}) {
    if (index !== this.index) {
      this.index = index
      this._scroll()
      this._updateAndDispatch()
    }
  }

  scrollToPrevious() {
    this.scrollToIndex({ detail: { index: this.index - 1 } })
  }

  scrollToNext() {
    this.scrollToIndex({ detail: { index: this.index + 1 } })
  }

  scrollToRandom() {
    this.index = Math.floor(Math.random() * this.itemTargets.length)
    this._scroll()
    this._updateAndDispatch()
  }

  calculateActiveIndex() {
    if (this.ignoreScrollEvents) {
      return
    }
    let scrollPosition = this.containerTarget.scrollLeft
    const maxScroll = this.containerTarget.scrollWidth - this.containerTarget.clientWidth
    const index = scrollPosition > maxScroll - 50
        ? this.itemTargets.length - 1
        : Math.round(scrollPosition / this.itemTarget.offsetWidth)
    if (index !== this.index) {
      this.index = index
      this._updateAndDispatch()
    }
  }

  _scroll() {
    const left = this.index * this.itemTarget.offsetWidth
    this.ignoreScrollEvents = true
    this.containerTarget.scrollTo({ left, behavior: this.scrollBehaviorValue })
    setTimeout(() => this.ignoreScrollEvents = false, 500)
  }

  _updateAndDispatch() {
    this._updateClasses()
    this.dispatch("indexChanged", { detail: { index: this.index } })
  }

  _updateClasses() {
    this.itemTargets.forEach((t, i) => t.classList.toggle("active", i === this.index))
    const left = this.index > 0
    const right = this.index < this.itemTargets.length - 1
    if (this.hasPrevTarget) {
      this.prevTarget.classList.toggle("hidden", !left)
    }
    if (this.hasNextTarget) {
      this.nextTarget.classList.toggle("hidden", !right)
    }
    if (this.hasDotTarget) {
      this.dotTargets.forEach((dot, index) => dot.classList.toggle("active", index === this.index))
    }
    if (this.maskValue) {
      this.containerTarget.classList.toggle("mask-gradient-l", left && !right)
      this.containerTarget.classList.toggle("mask-gradient-r", right && !left)
      this.containerTarget.classList.toggle("mask-gradient-h", left && right)
    }
  }
}
